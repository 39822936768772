import React from 'react'
import { Link } from 'gatsby'

import '../css/global.css'

class Header extends React.Component {
  render () {
    console.log(this.props.location.pathname)
    return (
      <div className='wave-container mb-6'>
        <svg className='mouvingwave absolute' xmlns='http://www.w3.org/2000/svg' height='50px' width='150%' viewBox='0 0 1440 320' preserveAspectRatio='none'>
          <path fill='#f56565' fillOpacity='1' d='M0,128L34.3,154.7C68.6,181,137,235,206,224C274.3,213,343,139,411,96C480,53,549,43,617,74.7C685.7,107,754,181,823,197.3C891.4,213,960,171,1029,160C1097.1,149,1166,171,1234,165.3C1302.9,160,1371,128,1406,112L1440,96L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z' />
        </svg>
        <div className='container mx-auto'>
          <div className='flex content-center justify-between' style={{ height: '70px' }}>
            <h1 className='changing-color-title m-3 font-semibold text-red-500 text-center sm:text-left text-5xl animated-title'>
              {this.props.title}
            </h1>

            {/* <div className='self-end pb-2'>
              <span className='relative'>
                <Link className={`header-link text-red-500 text-xl font-semibold mx-2 pb-1 ${this.props.location.pathname === '/' ? 'header-link-undeline' : ''}`} to='/'>Home</Link>
              </span>
              <span className='relative'>
                <Link className={`header-link text-red-500 text-xl font-semibold mx-2 pb-1 ${this.props.location.pathname.startsWith('/blog') ? 'header-link-undeline' : ''}`} to='blog'>Blog</Link>
              </span>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}
class Footer extends React.Component {
  render () {
    return (
      <footer className='bg-gray-800' >
        <div className='wave-container'>
          <svg transform='scale(1,-1)' className='mouvingwave relative' xmlns='http://www.w3.org/2000/svg' height='50px' width='150%' viewBox='0 0 1440 320' preserveAspectRatio='none'>
            <path fill='#f56565' fillOpacity='1' d='M0,128L34.3,154.7C68.6,181,137,235,206,224C274.3,213,343,139,411,96C480,53,549,43,617,74.7C685.7,107,754,181,823,197.3C891.4,213,960,171,1029,160C1097.1,149,1166,171,1234,165.3C1302.9,160,1371,128,1406,112L1440,96L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z' />
          </svg>
        </div>
      </footer>
    ) 
  }
}
  
class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    if (location.pathname === rootPath) {
      
    }

    return (
      <div className='bg-gray-800 text-gray-300 flex flex-col h-full'>
        <Header title={title} location={this.props.location} /> 
        <div className='container mx-auto flex-1'>
          <div className='p-3'>
            {children}
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default Layout
