import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { MdPhoneAndroid, MdEmail } from 'react-icons/md'

function _calculateAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function Bio() {
  const date = new Date().getFullYear() 
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, phone, email } = data.site.siteMetadata
        return (
          <div>
            <div className="md:flex">
              <img
                src={data.avatar.childImageSharp.fixed.src}
                alt={author}
                className="h-16 w-16 md:h-24 md:w-24 rounded-full mx-auto md:mx-0 md:mr-6"
              />
              <div className="text-center md:text-left">
                <p className="ml-3 font">
                  Hi, my name is <strong className="text-red-500">{author}</strong>, I'm {_calculateAge(new Date("08/07/1989"))} years old and I'm from Italy.
                </p>
                <p className="ml-3 font">
                  I have always been enthusiast by problem solving, and Computer Science is where I found the most interesting one
                </p>
                <p className="text-sm ml-3 font">
                  <MdPhoneAndroid style={{ display: "inline" }}/> {phone}
                </p>
                <p className="text-sm ml-3 font">
                  <MdEmail style={{ display: "inline" }}/> <a href={`mailto:${email}`}>{email}</a>
                </p>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        email
        phone
      }
    }
  }
`

export default Bio
